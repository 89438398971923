import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";

@Component({
  selector: "est-text",
  standalone: true,
  imports: [RouterOutlet, NavigationTabsComponent],
  templateUrl: "./text.component.html",
  styleUrl: "./text.component.scss",
})
export class TextComponent {
  protected tabs: INavigationTab[] = [
    {
      label: "TEXT_PAGE.WELCOME_PAGE.NAME",
      routerLink: "welcome-page",
    },
    {
      label: "TEXT_PAGE.CONFIRMATION_PAGE.NAME",
      routerLink: "confirmation-page",
    },
    {
      label: "TEXT_PAGE.ERROR_PAGE.NAME",
      routerLink: "error-page",
    },
  ];
}
